import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SingleValueDisplay from "./singleValueDisplay";
import useStyles from "./styles";
import { colors } from "../../theme";
interface IProps {
  current?: number;
  target?: number;
  currentMetric?: string;
  referenceMetric?: string;
  currentPeriodLabel: string;
  referencePeriodLabel: string;
  currentShiftLabel: string;
  referenceShiftLabel: string;
  isLoading?: boolean;
}

export default function ValueDisplay({
  current,
  target,
  currentMetric = "",
  referenceMetric = "",
  currentPeriodLabel,
  referencePeriodLabel,
  currentShiftLabel,
  referenceShiftLabel,
  isLoading = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  const mainContainerRef = useRef<HTMLDivElement>(null);
  const [forceWidth, setForceWidth] = useState<number>(0);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const updateWidth = useCallback(
    (width: number) => setForceWidth((prev) => Math.max(prev, width)),
    [],
  );

  const checkOverflow = useCallback(() => {
    return (
      mainContainerRef.current !== null &&
      mainContainerRef.current.offsetWidth <
        mainContainerRef.current.scrollWidth
    );
  }, [mainContainerRef, mainContainerRef.current?.offsetWidth]);

  useEffect(() => {
    const handleResize = () => {
      setIsOverflow(checkOverflow());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [checkOverflow, forceWidth]);

  const maxWidth = useMemo(() => {
    return mainContainerRef.current
      ? mainContainerRef.current.offsetWidth
      : "unset";
  }, [mainContainerRef.current?.offsetWidth]);

  const displayProps = (isTarget: boolean) => ({
    value: isTarget ? target : current,
    metric: isTarget ? referenceMetric : currentMetric,
    leftText: isTarget ? referencePeriodLabel : currentPeriodLabel,
    rightText: isTarget ? referenceShiftLabel : currentShiftLabel,
    style: isTarget ? { marginTop: "20px" } : {},
    updateWidth: updateWidth,
    forceWidth: forceWidth,
    isParentOverflow: isOverflow,
    maxWidth: maxWidth,
    isLoading: isLoading,
    lineColor: isTarget ? colors.greyB16 : colors.blue_base,
  });

  return (
    <div ref={mainContainerRef} className={classes.mainContainer}>
      <SingleValueDisplay {...displayProps(false)} />
      <SingleValueDisplay {...displayProps(true)} />
    </div>
  );
}

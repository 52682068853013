import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Skeleton, Typography } from "@mui/material";
import useStyles from "./styles";
import { Variant } from "@mui/material/styles/createTypography";
import { throttle } from "lodash";
import { useTranslation } from "react-i18next";
import formatNumberString from "../../../../helper/valueRepresentation/formatNumberString";

interface IProps {
  value: number | undefined;
  metric: string;
  leftText: string;
  rightText: string;
  style?: React.CSSProperties;
  updateWidth?: (width: number) => void; // Step 1: Add these props
  forceWidth?: number; // Step 1: Add these props
  maxWidth: number | string;
  isParentOverflow: boolean;
  lineColor: string;
  isLoading?: boolean;
}

function SingleValueDisplay({
  value,
  metric,
  leftText,
  rightText,
  style,
  updateWidth,
  forceWidth,
  maxWidth,
  isParentOverflow,
  lineColor,
  isLoading = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const prevTypographyWidthRef = useRef(0);
  const prevContainerWidthRef = useRef(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const typographyRef = useRef<HTMLDivElement>(null);
  const isValueUndefined = useMemo(
    () => typeof value === "undefined" || value === null,
    [value],
  );
  const displayValue = useMemo(() => {
    if (typeof value === "undefined") {
      return t("shared.components.LabelWithBox.noData");
    }
    return formatNumberString(value.toString());
  }, [value]);

  const [adjustedVariant, setAdjustedVariant] = useState<"d1" | "h2">("d1");
  const [noDataVariant, setNoDataVariant] = useState<"body1" | "caption">(
    "body1",
  );
  const [flexDirection, setFlexDirection] = useState<"column" | "row">(
    "column",
  );

  const { justifyContent, lineWidth, metricFontSize, metricLineHeight } =
    useMemo(() => {
      const forceLineWidth =
        typeof maxWidth === "number"
          ? `${Math.min(maxWidth, forceWidth ?? 0) - 10}px`
          : "calc(100% - 20px)";
      return {
        justifyContent: isParentOverflow ? "center" : "flex-end",
        lineWidth: isParentOverflow ? forceLineWidth : "100%",
        metricFontSize: isParentOverflow ? 16 : 20,
        metricLineHeight: isParentOverflow ? "22px" : "30px",
      };
    }, [isParentOverflow, maxWidth, forceWidth]);

  // Adjust styles based on parent overflow
  useEffect(() => {
    setAdjustedVariant(isParentOverflow ? "h2" : "d1");
    setNoDataVariant(isParentOverflow ? "caption" : "body1");
    setFlexDirection(isParentOverflow ? "column" : "row");
  }, [isParentOverflow]);

  // Throttle the updateWidth function. This ensures that the function is not called
  // more often than once every 200 milliseconds.
  const throttledUpdateWidth = useMemo(() => {
    if (updateWidth) {
      return throttle(updateWidth, 200);
    }
  }, [updateWidth]);

  const renderValue = () => {
    if (isValueUndefined) {
      return (
        <Typography
          width={"100%"}
          variant={noDataVariant as Variant}
          lineHeight={"23px"}
          color={"primary"}
        >
          {displayValue}
        </Typography>
      );
    }
    return (
      <div style={{ display: "flex" }}>
        <Typography
          variant={adjustedVariant as Variant}
          className={classes.valueContainer}
          color={"primary"}
        >
          {displayValue}
        </Typography>
        <Typography
          sx={{ fontSize: metricFontSize, lineHeight: metricLineHeight }}
          color={"textSecondary"}
          className={classes.metricContainer}
        >{`${metric}`}</Typography>
      </div>
    );
  };

  // Update width using refs
  useLayoutEffect(() => {
    const currentTypographyWidth = typographyRef.current?.offsetWidth;
    const currentContainerWidth = containerRef.current?.offsetWidth;

    if (
      currentTypographyWidth &&
      currentTypographyWidth !== prevTypographyWidthRef.current
    ) {
      throttledUpdateWidth?.(currentTypographyWidth);
      prevTypographyWidthRef.current = currentTypographyWidth;
    }
    if (
      currentContainerWidth &&
      currentContainerWidth !== prevContainerWidthRef.current
    ) {
      throttledUpdateWidth?.(currentContainerWidth);
      prevContainerWidthRef.current = currentContainerWidth;
    }
  }, [throttledUpdateWidth, displayValue]);

  return (
    <div
      className={classes.container}
      ref={containerRef}
      style={{
        ...style,
        width: forceWidth ? forceWidth : "auto",
        maxWidth: maxWidth,
      }}
    >
      <div
        style={{
          width: forceWidth ? forceWidth : "auto",
          display: "grid",
        }}
      >
        <div
          ref={typographyRef}
          className={classes.valueMetricContainer}
          style={{ width: "100%", justifyContent }}
        >
          {isLoading ? (
            <Skeleton variant="text" width={150} height={60} />
          ) : (
            renderValue()
          )}
        </div>
        <div
          id={"belowTextContainer"}
          style={{ width: "100%", display: "contents" }}
        >
          <div
            style={{
              width: lineWidth,
              backgroundColor: lineColor,
            }}
            className={classes.lineContainer}
          ></div>
          <div
            style={{ flexDirection }}
            id={"belowValueTextContainer"}
            className={classes.belowValueTextContainer}
          >
            <Typography
              variant="caption"
              color={"primary"}
              style={isParentOverflow ? {} : { marginRight: "10px" }}
            >
              {leftText}
            </Typography>
            <Typography variant="caption" color={"primary"}>
              {rightText}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(SingleValueDisplay);
